<script setup lang="ts">
import MobilisImg from '~/assets/img/mobilis.png'
const nowDate = ref(useNow())

onMounted(() => {
  setInterval(() => {
    nowDate.value = useNow()
  }, 1000)
})
</script>

<template>
  <header class="pt-12 pb-16">
    <div class="flex justify-between">
      <NuxtLink to="/">
        <SvgoLogoVmcv class="w-32" />
      </NuxtLink>
      <div class="flex flex-col items-center space-y-2">
        <NuxtLink
          target="_blank"
          class="text-vmcv-blue font-condensed font-bold text-xs"
          to="https://mobilis-vaud.ch"
        >
          <img :src="MobilisImg" class="object-contain w-32" />
          <div class="mt-4">www.mobilis-vaud.ch</div>
        </NuxtLink>
        <div class="font-condensed font-bold text-xs">
          {{ nowDate }}
        </div>
      </div>
    </div>
  </header>
</template>
